import { firestore, functions } from "utils/firebase";

export function getStripeConnectAccount(stripe, stripeID) {
    console.log(stripe)
    return 'test'
}

export async function getStripeCustomerAndPaymentMethods(stripeCustomerId) {
    const res = await functions.httpsCallable('getPaymentMethods')({ stripeCustomerId })
        .catch((err) => {
            console.log('Error getting payment methods: ', err)
            return null
        })

    return {
        customer: res.data.customer,
        paymentMethods: res.data.paymentMethods
    }

}

export const deletePaymentMethod = (paymentMethodId, onSuccess) => {
    functions
      .httpsCallable("deletePaymentMethod")({ paymentMethodId })
      .then(() => {
        onSuccess && onSuccess();
      })
      .catch((e) => {
        console.log("deletePaymentMethod error", e);
      });
  }
  
  export const updateDefaultPaymentMethod = (paymentMethodId, stripeCustomerId, onSuccess) => {
    functions
      .httpsCallable("updateDefaultPaymentMethod")({ paymentMethodId, stripeCustomerId })
      .then((res) => {
        console.log(res)
        onSuccess && onSuccess();
      })
      .catch((e) => {
        console.log("updateDefaultPaymentMethod error", e);
      });
  }
  