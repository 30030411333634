const regexPatterns = {
	email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
	url: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
	phone: /^\+?[1-9]\d{4,14}$/,
	facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9._-]+\/?$/,
	instagram: /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._-]+\/?$/,
	linkedin: /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9._-]+\/?$/,
	twitter: /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9._-]+\/?$/,
	number: /^\d+$/,
	color: /^#[0-9A-Fa-f]{6}$/,
	ein: /^\d{2}-\d{7}$/,
	name: /^.{4,}$/
};

const messages = {
	email: "Please enter a valid email address.",
	url: "Please enter a valid URL.",
	phone: "Please enter a valid phone number.",
	facebook: "Please enter a valid Facebook profile link.",
	instagram: "Please enter a valid Instagram profile link.",
	linkedin: "Please enter a valid LinkedIn profile link.",
	twitter: "Please enter a valid Twitter profile link.",
	number: "Please enter a valid number.",
	color: "Please enter a valid color code.",
	ein: "Please enter a valid Employer Identification Number (EIN).",
	name: "Please enter a valid organization name."
};

/* 
    value: The value to be validated.
    type: The type of validation to be performed (e.g., email, url, phone).
    message: An optional custom error message.
    name: An optional name for the error object.
*/
export default function validate({ value, type, message, name }) {
	const pattern = regexPatterns[type];

	if (!pattern) throw new Error(`No regex pattern defined for type: ${type}`);

	const isValid = pattern.test(value);

	console.log({ isValid, type, value });

	if (!isValid) {
		const error = new Error(message || messages[type]);
		error.name = name || type;
		throw error;
	}

	return value;
}
