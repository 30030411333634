import { Box } from "@mui/material";
import { Edit } from "assets/svgs";
import SaveAndCancelBtns from "components/Buttons/SaveAndCancelBtns";
import { PrimaryText } from "components/Common/PrimaryText";
import { CustomInput } from "components/Inputs/CustomInput";
import { COLORS } from "constants/Colors";
import React from "react";
import { useState } from "react";
import { TouchableOpacity } from "react-native-web";
import validate from "utils/validation";

const DEFAULT_DONATIONS = [
	{
		amount: 25,
		default: false
	},
	{
		amount: 50,
		default: false
	},
	{
		amount: 100,
		default: true
	},
	{
		amount: 250,
		default: false
	}
];

export default function DonationInputs({
	isEditing,
	onEdit,
	property,
	onSave,
	onCancel,
	donations
}) {
	const [updatedDonations, setUpdatedDonations] = useState(
		donations?.length ? donations : DEFAULT_DONATIONS
	);

	const resetDonations = () =>
		setUpdatedDonations(donations?.length ? donations : DEFAULT_DONATIONS);

	isEditing = isEditing === property;

	// Handle the change in donation by validating the number
	const handleChange = async (index, value) => {
		try {
			if (value) validate({ value, type: "number" });

			const donationArray = [...updatedDonations];

			donationArray[index] = {
				...donationArray[index],
				amount: value
			};

			setUpdatedDonations(donationArray);
		} catch (e) {
			console.log({ ...e });
		}
	};

	return (
		<Box>
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
			>
				<PrimaryText
					fontSize={17}
					fontWeight={600}
				>
					Custom Donation Amounts
				</PrimaryText>

				{!isEditing && (
					<TouchableOpacity
						style={{ marginLeft: 12 }}
						onPress={() => onEdit(property)}
					>
						<Edit fill={COLORS.primary.darkBlue} />
					</TouchableOpacity>
				)}
			</Box>

			<Box
				display="flex"
				flexDirection="column"
			>
				{updatedDonations?.map((donation, index) => (
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						mt={8}
						gap={8}
					>
						<PrimaryText
							fontSize={17}
							fontWeight={900}
							color={COLORS.primary.neutral_800}
						>
							{index + 1}.
						</PrimaryText>
						{isEditing ? (
							<CustomInput
								style={{ width: 111 }}
								value={donation.amount}
								onChangeText={(e) => handleChange(index, e)}
							/>
						) : (
							<PrimaryText
								fontSize={17}
								fontWeight={900}
								color={COLORS.primary.neutral_800}
							>
								${donation.amount}
							</PrimaryText>
						)}
						{donation?.default && (
							<PrimaryText
								fontSize={12}
								fontWeight={600}
								color={COLORS.primary.lightBlue}
								style={{ fontStyle: "italic" }}
							>
								Default Donation
							</PrimaryText>
						)}
					</Box>
				))}
			</Box>

			{isEditing && (
				<SaveAndCancelBtns
					onSave={() => onSave(property, updatedDonations)}
					onCancel={() => {
						resetDonations();
						onCancel?.();
					}}
				/>
			)}
		</Box>
	);
}
