import React, { createContext, useContext, useEffect, useState } from "react";

import { useAuth } from "./AuthContext";
import {
  getOrgCoverPhoto,
  getOrgInvitedMembers,
  getOrgMembers,
  getUserOrgs,
} from "services/organizations-service";
import { getOrgsPosts } from "services/posts-service";
import { getEvents } from "services/events-service";
import { getOurCauses } from "services/causes-service";
import { useCauses } from "./CausesContext";
import { functions } from "utils/firebase";

const OrganizationContext = createContext();

export const OrganizationProvider = (props) => {
  const { currentUser, onboarding, setOnboarding } = useAuth();
  const { allCauses } = useCauses();

  const [ourOrgs, setOurOrgs] = useState([]);
  const [ourOrgOptions, setOurOrgOptions] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [members, setMembers] = useState([]);
  const [users, setUsers] = useState(null);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [causes, setCauses] = useState([]);
  const [owner, setOwner] = useState(false);
  const [userType, setUserType] = useState("");
  const [currentMember, setCurrentMember] = useState(null);
  const [sponsors, setSponsors] = useState([]);
  const [coverPhoto, setCoverPhoto] = useState(null);

  const [canMakeActions, setCanMakeActions] = useState(false);

  //NEW ORG
  const [newOrg, setNewOrg] = useState(null);
  const [newOrgCoverPhotos, setNewOrgCoverPhotos] = useState([]);

  //FEED
  const [feed, setFeed] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [refreshing, setRefreshing] = useState(true);
  const [lastLoaded, setLastLoaded] = useState(null);

  //state
  const [loading, setLoading] = useState(true);

  let orgSubscriber;
  useEffect(() => {
    if (currentUser && !onboarding) {
      orgSubscriber = getUserOrgs(
        currentUser,
        gotOurOrgs,
        selectedOrg,
        setSelectedOrg
      );
    } else {
      setOurOrgs([]);
    }
    return () => {
      orgSubscriber && orgSubscriber();
    };
  }, [currentUser, onboarding]);

  function gotOurOrgs(orgs) {
    if (orgs?.length === 0) {
      setOnboarding(true);
    } else {
      setOnboarding(false);
      setOurOrgs(orgs);
    }
  }

  useEffect(() => {
    if (!selectedOrg) {
      setMembers([]);
      setOwner(false);
      setUserType("");
      setSponsors([]);
      setCoverPhoto(null);
      setCanMakeActions(false);
      return;
    }
    onRefresh(true);
    setCoverPhoto(selectedOrg.coverPhoto);

    const memberSub = getOrgMembers(
      currentUser,
      selectedOrg,
      setMembers,
      setSponsors,
      setUserType,
      setCurrentMember,
      setLoading
    );
    const inviteSub = getOrgInvitedMembers(selectedOrg, setInvitedMembers);
    setOwner(selectedOrg?.owner === currentUser.id);

    return () => {
      memberSub();
      inviteSub();
    };
  }, [selectedOrg, currentUser]);

  useEffect(() => {
    if (!selectedOrg || !members) return;
    const membersMutate = members.map((x) => {
      return { uid: x.id };
    });
    const stringifiedMembers = JSON.stringify(membersMutate);
    functions
      .httpsCallable("getAuthUsers")(stringifiedMembers)
      .then((res) => {
        let temp = [];
        members.forEach((member) => {
          const find = res?.data.find((x) => x.uid === member.id);
          if (find) member.email = find.email;
          temp.push(member);
        });
        setUsers(temp);
      })
      .catch((e) => {
        console.log("error getting auth users", e);
      });
  }, [members, selectedOrg]);

  //set our causes
  useEffect(() => {
    if (!selectedOrg || !allCauses) return;
    getOurCauses(selectedOrg, allCauses, setCauses);
  }, [selectedOrg, allCauses]);

  useEffect(() => {
    if (coverPhoto && selectedOrg) {
      selectedOrg.coverPhoto = coverPhoto;
    }
  }, [coverPhoto]);

  useEffect(() => {
    if (ourOrgs && selectedOrg) {
      let find = ourOrgs.find((x) => x.id === selectedOrg.id);
      find && setSelectedOrg(find);
    }
    if (ourOrgs) {
      setOurOrgOptions(
        ourOrgs.map((x) => {
          return { value: x.id, label: x.name, org: x };
        })
      );
    }
  }, [ourOrgs]);

  useEffect(() => {
    if (!newOrg) {
      setNewOrgCoverPhotos([]);
    }
  }, [newOrg]);

  /** 1a. WHEN WE INITIALLY LOAD OR PULL REFRESH */
  const onRefresh = (showLoader) => {
    // console.log('onRefresh showLoader:', showLoader)
    showLoader && setRefreshing(true);
    getOrgsPosts(selectedOrg, setFeed, setLastLoaded);
    // getOrgFeed(
    //   currentUser,
    //   selectedOrg.id,
    //   feed,
    //   setFeed,
    //   null,
    //   setLastLoaded,
    //   setFetching,
    //   setRefreshing
    // )
  };

  /** 2. WHEN END IS REACHED AND WE AREN'T CURRENTLY REFRESHING */

  const loadMoreFeed = () => {
    // console.log('loadMoreFeed!', loadMore, fetching)
    if (fetching) return;
    // console.log('get some')
    setFetching(true);
    // getOrgFeed(
    //   currentUser,
    //   selectedOrg.id,
    //   feed,
    //   setFeed,
    //   lastLoaded,
    //   setLastLoaded,
    //   setFetching,
    //   setRefreshing
    // )
  };

  return (
    <OrganizationContext.Provider
      value={{
        ourOrgs,
        setOurOrgs,
        ourOrgOptions,
        setOurOrgOptions,
        selectedOrg,
        setSelectedOrg,
        members,
        setMembers,
        users,
        setUsers,
        invitedMembers,
        currentMember,
        causes,
        setCauses,
        coverPhoto,
        setCoverPhoto,

        owner,
        userType,
        sponsors,
        loading,

        newOrg,
        setNewOrg,
        newOrgCoverPhotos,
        setNewOrgCoverPhotos,

        feed,
        refreshing,
        onRefresh,
        loadMoreFeed,
      }}
    >
      {props.children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => useContext(OrganizationContext);
